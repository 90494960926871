export const ParaChainNetworkConfigs = [
  {
    decimals: [10],
    displayName: 'Polkadot Relay Chain',
    network: 'polkadot',
    prefix: 0,
    standardAccount: '*25519',
    symbols: ['DOT'],
    website: 'https://polkadot.network',
    wssEndpoints: ['wss://rpc.polkadot.io'],
  },
  {
    decimals: [12],
    displayName: 'Kusama Relay Chain',
    network: 'kusama',
    prefix: 2,
    standardAccount: '*25519',
    symbols: ['KSM'],
    website: 'https://kusama.network',
    wssEndpoints: ['wss://kusama-rpc.polkadot.io'],
  },

  // ========Polkadot Parachain======
  {
    decimals: [12],
    displayName: 'Acala',
    network: 'acala',
    prefix: 10,
    standardAccount: '*25519',
    symbols: ['ACA'],
    website: 'https://acala.network/',
    wssEndpoints: ['wss://acala-rpc-0.aca-api.network'],
  },

  {
    decimals: [18],
    displayName: 'Moonbeam',
    network: 'moonbeam',
    prefix: 1284,
    standardAccount: 'secp256k1',
    symbols: ['GLMR'],
    website: 'https://moonbeam.network',
    wssEndpoints: ['wss://wss.api.moonbeam.network'],
  },

  {
    decimals: [12],
    displayName: 'Parallel',
    network: 'parallel',
    prefix: 172,
    standardAccount: '*25519',
    symbols: ['PARA'],
    website: 'https://parallel.fi/',
    wssEndpoints: ['wss://parallel.api.onfinality.io/public-ws'],
  },
  {
    decimals: [12],
    displayName: 'Ares Protocol',
    network: 'ares',
    prefix: 34,
    standardAccount: '*25519',
    symbols: ['ARES'],
    website: 'https://www.aresprotocol.com/',
    wssEndpoints: ['wss://wss.odyssey.aresprotocol.io'],
  },

  {
    decimals: [18],
    displayName: 'Astar',
    network: 'astar',
    prefix: 5,
    standardAccount: '*25519',
    symbols: ['ASTR'],
    website: '',
    wssEndpoints: ['wss://astar.api.onfinality.io/public-ws'],
  },

  {
    decimals: [18],
    displayName: 'Clover Finance',
    network: 'clover',
    prefix: 128,
    standardAccount: '*25519',
    symbols: ['CLV'],
    website: 'https://clover.finance',
    wssEndpoints: ['wss://clover.api.onfinality.io/public-ws'],
  },

  {
    decimals: [18],
    displayName: 'Manta network',
    network: 'manta',
    prefix: 77,
    standardAccount: '*25519',
    symbols: ['MANTA'],
    website: 'https://manta.network',
    wssEndpoints: ['wss://pectinata.manta.systems'],
  },
  {
    decimals: [18],
    displayName: 'Litentry Network',
    network: 'litentry',
    prefix: 31,
    standardAccount: '*25519',
    symbols: ['LIT'],
    website: 'https://litentry.com/',
    wssEndpoints: ['wss://litentry-rpc.dwellir.com', 'wss://rpc.litentry-parachain.litentry.io'],
  },
  {
    decimals: [12],
    displayName: 'Litentry Rococo Network',
    network: 'Litentry-rococo',
    prefix: 131,
    standardAccount: '*25519',
    symbols: ['LIT'],
    website: 'https://litentry.com/',
    wssEndpoints: [],
    genesisHash: '0x6940b1852737722c0aa768710e20105ab8c6a80b63a0339cb99892ffdce38283'
  },

  // ========Kusama Parachain======

  {
    decimals: [12],
    displayName: 'Karura',
    network: 'karura',
    prefix: 8,
    standardAccount: '*25519',
    symbols: ['KAR'],
    website: 'https://karura.network/',
    wssEndpoints: ['wss://karura-rpc-0.aca-api.network'],
  },
  {
    decimals: [18],
    displayName: 'Moonriver',
    network: 'moonriver',
    prefix: 1285,
    standardAccount: 'secp256k1',
    symbols: ['MOVR'],
    website: 'https://moonbeam.network',
    wssEndpoints: ['wss://rpc.pinknode.io/moonriver/explorer'],
  },

  {
    decimals: [12],
    displayName: 'Bifrost',
    network: 'bifrost',
    prefix: 6,
    standardAccount: '*25519',
    symbols: ['BNC'],
    website: 'https://bifrost.finance/',
    wssEndpoints: ['wss://bifrost-rpc.liebi.com/ws'],
  },
  {
    decimals: [10],
    displayName: 'Turing Network',
    network: 'turing',
    prefix: 51,
    standardAccount: '*25519',
    symbols: ['TUR'],
    website: 'https://oak.tech/',
    wssEndpoints: ['wss://rpc.turing-staging.oak.tech'],
    // wssEndpoints: ['wss://rpc.turing.oak.tech'],
  },
  {
    decimals: [18],
    displayName: 'Pioneer Network by Bit.Country',
    network: 'pioneer_network',
    prefix: 268,
    standardAccount: '*25519',
    symbols: ['NEER'],
    website: 'https://bit.country',
    wssEndpoints: ['wss://pioneer-1-rpc.bit.country'],
  },
  {
    decimals: [12],
    displayName: 'Heiko',
    network: 'heiko',
    prefix: 110,
    standardAccount: '*25519',
    symbols: ['HKO'],
    website: 'https://parallel.fi/',
    wssEndpoints: ['wss://heiko-rpc.parallel.fi'],
  },
  // {
  //   decimals: [12],
  //   displayName: 'Integritee',
  //   network: 'integritee',
  //   prefix: 13,
  //   standardAccount: '*25519',
  //   symbols: ['TEER'],
  //   website: 'https://integritee.network',
  //   wssEndpoints: ['wss://kusama.api.integritee.network'],
  // },

  {
    decimals: [18],
    displayName: 'Shiden Network',
    network: 'shiden',
    prefix: 5,
    standardAccount: '*25519',
    symbols: ['SDN'],
    website: '',
    wssEndpoints: ['wss://rpc.shiden.astar.network'],
  },
  {
    decimals: [12],
    displayName: 'Khala Network',
    network: 'khala',
    prefix: 30,
    standardAccount: '*25519',
    symbols: ['PHA'],
    website: 'https://phala.network',
    wssEndpoints: ['wss://khala-api.phala.network/ws'],
  },

  {
    decimals: [15],
    displayName: 'KILT Chain',
    network: 'kilt',
    prefix: 38,
    standardAccount: '*25519',
    symbols: ['KILT'],
    website: 'https://kilt.io/',
    wssEndpoints: ['wss://spiritnet.kilt.io/'],
  },
  {
    decimals: [18],
    displayName: 'Altair',
    network: 'altair',
    prefix: 136,
    standardAccount: '*25519',
    symbols: ['AIR'],
    website: 'https://centrifuge.io/',
    wssEndpoints: ['wss://altair.api.onfinality.io/public-ws'],
  },
  {
    decimals: [12],
    displayName: 'Basilisk',
    network: 'basilisk',
    prefix: 10041,
    standardAccount: '*25519',
    symbols: ['BSX'],
    website: 'https://bsx.fi',
    wssEndpoints: ['wss://basilisk.api.onfinality.io/public-ws'],
  },
  {
    decimals: [12],
    displayName: 'Crust Network',
    network: 'crust',
    prefix: 66,
    standardAccount: '*25519',
    symbols: ['CRU'],
    website: 'https://crust.network',
    wssEndpoints: ['wss://rpc.crust.network'],
  },

  {
    decimals: [18],
    displayName: 'QUARTZ by UNIQUE',
    network: 'quartz_mainnet',
    prefix: 255,
    standardAccount: '*25519',
    symbols: ['QTZ'],
    website: 'https://unique.network',
    wssEndpoints: ['wss://quartz.unique.network'],
  },
  {
    decimals: [12],
    displayName: 'Calamari: Manta Canary Network',
    network: 'calamari',
    prefix: 78,
    standardAccount: '*25519',
    symbols: ['KMA'],
    website: 'https://manta.network',
    wssEndpoints: ['wss://calamari.api.onfinality.io/public-ws'],
  },
  {
    decimals: [18],
    displayName: 'Centrifuge Chain',
    network: 'centrifuge',
    prefix: 36,
    standardAccount: '*25519',
    symbols: ['CFG'],
    website: 'https://centrifuge.io/',
    wssEndpoints: ['wss://fullnode.centrifuge.io'],
  },
  // {
  //   decimals: [11],
  //   displayName: 'Subsocial',
  //   network: 'subsocial',
  //   prefix: 28,
  //   standardAccount: '*25519',
  //   symbols: ['SUB'],
  //   website: null,
  //   wssEndpoints: ['wss://subsocial.api.onfinality.io/public-ws'],
  // },
  {
    decimals: [18],
    displayName: 'SORA Network',
    network: 'sora',
    prefix: 69,
    standardAccount: '*25519',
    symbols: ['XOR'],
    website: 'https://sora.org',
    wssEndpoints: ['wss://mof2.sora.org'],
  },
  {
    decimals: [18],
    displayName: 'Edgeware',
    network: 'edgeware',
    prefix: 7,
    standardAccount: '*25519',
    symbols: ['EDG'],
    website: 'https://edgewa.re',
    wssEndpoints: ['wss://edgeware.api.onfinality.io/public-ws'],
  },
  {
    decimals: [8],
    displayName: 'ChainX',
    network: 'chainx',
    prefix: 44,
    standardAccount: '*25519',
    symbols: ['PCX'],
    website: 'https://chainx.org/',
    wssEndpoints: ['wss://mainnet.chainx.org/ws'],
  },
  {
    decimals: [11],
    displayName: 'Nodle Chain',
    network: 'nodle',
    prefix: 37,
    standardAccount: '*25519',
    symbols: ['NODL'],
    website: 'https://nodle.io/',
    wssEndpoints: ['wss://main3.nodleprotocol.io'],
  },

  {
    decimals: [9, 9],
    displayName: 'Darwinia Network',
    network: 'darwinia',
    prefix: 18,
    standardAccount: '*25519',
    symbols: ['RING', 'KTON'],
    website: 'https://darwinia.network/',
    wssEndpoints: ['wss://rpc.darwinia.network'],
  },
  {
    decimals: [12],
    displayName: 'Picasso',
    network: 'picasso',
    prefix: 49,
    standardAccount: '*25519',
    symbols: ['PICA'],
    website: 'https://picasso.composable.finance',
  },
  {
    decimals: [9],
    displayName: 'Robonomics',
    network: 'robonomics',
    prefix: 32,
    standardAccount: '*25519',
    symbols: ['XRT'],
    website: 'https://robonomics.network',
  },
];
