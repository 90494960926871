export const ParaChainStyle = [
  {
    name: "Moonbeam",
    network: "moonbeam",
    icon: require("@/assets/images/home/image107.png"),
    bgIcon: require("@/assets/images/home/image103.png"),
    tagBgColor: "rgba(225, 20, 123, 0.1)",
    tagColor: "#e1147b",
    bgColor: "linear-gradient(180deg, #faf0f5 0%, #ffffff 79.37%)",
  },
  {
    name: "Moonriver",
    network: "moonriver",
    icon: require("@/assets/images/home/image22.png"),
    bgIcon: require("@/assets/images/home/image23.png"),
    tagBgColor: "rgba(242, 183, 5, 0.1)",
    tagColor: "#f2b705",
    bgColor: "linear-gradient(180deg, #faf6eb 0%, #ffffff 79.37%)",
  },
  {
    name: "Litentry",
    network: "litentry",
    icon: require("@/assets/images/home/logolitentrygreen.png"),
    // bgIcon: require("@/assets/images/home/logolitentrygreen(1).png"),
    bgIcon: require("@/assets/images/home/litentry/litentry.png"),
    // tagBgColor: "rgba(28, 198, 118, 0.1)",
    tagBgColor: "#1CC575",
    tagColor: "#FBFBFB",
    bgColor: "linear-gradient(180deg, #89E9D8 0%, #15B786 100%)",
    // bgColor: "linear-gradient(180deg, #e6f7ef 0%, #ffffff 79.37%)",
  },
  {
    name: "Litentry-rococo",
    network: "Litentry-rococo",
    icon: require("@/assets/images/home/logolitentrygreen.png"),
    bgIcon: require("@/assets/images/home/logolitentrygreen(1).png"),
    tagBgColor: "rgba(28, 198, 118, 0.1)",
    tagColor: "#1cc676",
    bgColor: "linear-gradient(180deg, #e6f7ef 0%, #ffffff 79.37%)",
    // bgColor: "linear-gradient(180deg, #e6f7ef 0%, #ffffff 79.37%)",
  },
  // {
  //   name: "MANTA",
  //   network: "manta",
  //   icon: require("@/assets/images/home/image104.png"),
  //   bgIcon: require("@/assets/images/home/image105.png"),
  //   tagBgColor: "rgba(16, 167, 228, 0.1)",
  //   tagColor: "#10a7e4",
  // bgColor: "linear-gradient(180deg, #faf0f5 0%, #ffffff 79.37%)",

  // },
  {
    name: "Calamari",
    network: "calamari",
    icon: require("@/assets/images/home/image24.png"),
    bgIcon: require("@/assets/images/home/image25.png"),
    tagBgColor: "rgba(16, 167, 228, 0.1)",
    tagColor: "#10a7e4",
    bgColor: "linear-gradient(180deg, #fff2f3 0%, #ffffff 79.37%)",
  },
  {
    name: "Turing",
    network: "turing",
    icon: require("@/assets/images/home/image108.png"),
    bgIcon: require("@/assets/images/home/image108(1).png"),
    tagBgColor: "rgba(213, 92, 107, 0.1)",
    tagColor: "#D55B6C",
    bgColor: "linear-gradient(180deg, #ede7ff 0%, #ffffff 79.37%)",
  },
  {
    name: "Bifrost",
    network: "bifrost",
    icon: require("@/assets/images/home/Vector.png"),
    bgIcon: require("@/assets/images/home/Vector(1).png"),
    tagBgColor: "rgba(78, 35, 250, 0.1)",
    tagColor: "#4e23fa",
    bgColor: "linear-gradient(180deg, #ede7ff 0%, #ffffff 79.37%)",
  },
];
