import { createRouter, createWebHashHistory } from "vue-router";
import { supports } from "@/api/staking";
import store from "@/store";
import { ParaChainStyle } from "@/utils/chain/parachainStyle";
const routes = [
  {
    path: "/",
    name: "Layout",
    redirect: "home",
    component: () => import("@/views/Layout"),
    beforeEnter: async (to, from, next) => {
      const supportChainList = await supports();
      // const a = [
      //   {
      //     id: "litentry",
      //     decimals: [12],
      //     displayName: "Litentry Network",
      //     network: "litentry",
      //     prefix: 31,
      //     standardAccount: "*25519",
      //     symbols: ["LIT", "LIT(ERC20)"],
      //     website: "https://litentry.com/",
      //     wssEndpoints: [
      //       "wss://rpc.rococo-parachain.litentry.io",
      //       // "wss://rpc.litentry-parachain.litentry.io"
      //     ],
      //   },
      //   {
      //     id: "calamari",
      //     decimals: [12],
      //     displayName: "Calamari: Manta Canary Network",
      //     network: "calamari",
      //     prefix: 78,
      //     standardAccount: "*25519",
      //     symbols: ["KMA"],
      //     website: "https://manta.network",
      //     wssEndpoints: ["wss://ws.calamari.systems/"],
      //   },
      //   {
      //     id: "turing",
      //     decimals: [10],
      //     displayName: "Turing Network",
      //     network: "turing",
      //     prefix: 51,
      //     standardAccount: "*25519",
      //     symbols: ["TUR"],
      //     website: "https://oak.tech/",
      //     wssEndpoints: ["wss://rpc.turing.oak.tech"],
      //   },
      //   {
      //     id: "bifrost",
      //     decimals: [12],
      //     displayName: "Bifrost",
      //     network: "bifrost",
      //     prefix: 6,
      //     standardAccount: "*25519",
      //     symbols: ["BNC"],
      //     website: "https://bifrost.finance/",
      //     wssEndpoints: ["wss://bifrost-rpc.liebi.com/ws"],
      //   },
      //   {
      //     id: "moonbeam",
      //     decimals: [18],
      //     displayName: "Moonbeam",
      //     network: "moonbeam",
      //     prefix: 1284,
      //     standardAccount: "secp256k1",
      //     symbols: ["GLMR"],
      //     website: "https://moonbeam.network",
      //     wssEndpoints: [
      //       "wss://wss.api.moonbeam.network",
      //       "wss://public-rpc.pinknode.io/moonbeam",
      //       "wss://moonbeam.public.blastapi.io",
      //     ],
      //   },
      //   {
      //     id: "moonriver",
      //     decimals: [18],
      //     displayName: "Moonriver",
      //     network: "moonriver",
      //     prefix: 1285,
      //     standardAccount: "secp256k1",
      //     symbols: ["MOVR"],
      //     website: "https://moonbeam.network",
      //     wssEndpoints: [
      //       "wss://wss.api.moonriver.moonbeam.network",
      //       "wss://moonriver.public.blastapi.io",
      //       "wss://public-rpc.pinknode.io/moonriver",
      //     ],
      //   },
      // ];
      const arr = [
        ...supportChainList,
        // {
        //     decimals: [10],
        //     displayName: 'Turing Network',
        //     network: 'turing',
        //     prefix: 51,
        //     standardAccount: '*25519',
        //     symbols: ['TUR'],
        //     website: 'https://oak.tech/',
        //     // wssEndpoints: ['wss://rpc.turing-staging.oak.tech'],
        //     wssEndpoints: ['wss://rpc.turing.oak.tech'],
        // },
        //     {
        //     decimals: [18],
        //     displayName: 'Moonriver',
        //     network: 'moonriver',
        //     prefix: 1285,
        //     standardAccount: 'secp256k1',
        //     symbols: ['MOVR'],
        //     website: 'https://moonbeam.network',
        //     wssEndpoints: ['wss://rpc.pinknode.io/moonriver/explorer'],
        // }
      ].map((v) => {
        const find = ParaChainStyle.find((sv) => sv.network == v.network);
        if (find) {
          return {
            ...v,
            ...find,
          };
        } else {
          return v;
        }
      });

      store.commit("changeSupportChainList", arr);
      next();
    },
    children: [
      {
        path: "home",
        name: "home",
        meta: {
          title: "Summary",
          // title: 'Web3Go Staking'
        },
        component: () => import("@/views/home"),
      },
      {
        path: "leaderBoard",
        name: "leaderBoard",
        meta: {
          title: "Leaderboard",
        },
        component: () => import("@/views/leaderBoard"),
      },
      {
        path: "myStake",
        name: "myStake",
        meta: {
          title: "My Stake",
        },
        component: () => import("@/views/myStake"),
      },
      {
        path: "collatorDetail",
        name: "collatorDetail",
        meta: {
          title: "Collator Detail",
        },
        component: () => import("@/views/collatorDetail"),
      },
      {
        path: "delegatorDetail",
        name: "delegatorDetail",
        meta: {
          title: "Delegator Detail",
        },
        component: () => import("@/views/delegatorDetail"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.name !== "home" && !store.state.global.currentChain.network) {
    next({
      name: "home",
    });
    return;
  }
  next();
});
export default router;
