import {
  encodeMetamaskAddressToLitentryAddress,
  ifSupportMetamask,
  ifSupportPolkadot,
} from "@/utils";
import { ChainUtils } from "@/utils/chain/chain.utils";

const defaultMetamaskWallet = localStorage.getItem("metamaskWallet");
const defaultPolkadotWallet = localStorage.getItem("polkadotWallet");
const defaultPolkadotWalletList = localStorage.getItem("polkadotWalletList");
const defaultCurrentChain = sessionStorage.getItem("currentChain");

export default {
  state() {
    return {
      metamaskWallet: defaultMetamaskWallet
        ? JSON.parse(defaultMetamaskWallet)
        : { meta: {} },
      polkadotWallet: defaultPolkadotWallet
        ? JSON.parse(defaultPolkadotWallet)
        : { meta: {} },
      polkadotWalletList: defaultPolkadotWalletList
        ? JSON.parse(defaultPolkadotWalletList)
        : [],
      supportChainList: [],
      currentChain: defaultCurrentChain ? JSON.parse(defaultCurrentChain) : {},
      walletType: JSON.parse(defaultPolkadotWallet)?.address
        ? "polkadot"
        : JSON.parse(defaultMetamaskWallet)?.address
        ? "metamask"
        : "",
      availableWallet: {
        metamask: !!JSON.parse(defaultMetamaskWallet)?.address,
        polkadot: !!JSON.parse(defaultPolkadotWallet)?.address,
      },
    };
  },
  getters: {
    ifLogin(state) {
      if (state.metamaskWallet.address || state.polkadotWallet.address) {
        return true;
      }
      return false;
    },
    supportAllWallet(state) {
      return (
        ifSupportPolkadot(state.currentChain.network) &&
        ifSupportMetamask(state.currentChain.network)
      );
    },
    allWalletAvailable(state) {
      return state.availableWallet.metamask && state.availableWallet.polkadot;
    },

    ifHasCurrentChainWallet(state, getters) {
      if (
        !getters.ifLogin ||
        (ifSupportPolkadot(state.global.currentChain.network) &&
          !getters.polkadotWalletTransformAddress) ||
        (!ifSupportMetamask(state.global.currentChain.network) &&
          !state.global.metamaskWallet.address)
      ) {
        return true;
      }
      return false;
    },
    polkadotWalletTransformAddress(state) {
      if (state.currentChain.network) {
        const transformAddress = ChainUtils.ss58transform_simple(
          state.polkadotWallet.address,
          state.currentChain.network
        );
        return transformAddress;
      }
      return state.polkadotWallet.address;
    },
    currentChainWalletAddressObject(state, getters) {
      return {
        polkadot: ifSupportPolkadot(state.currentChain.network)
          ? getters.polkadotWalletTransformAddress
          : undefined,
        metamask: ifSupportMetamask(state.currentChain.network)
          ? state.metamaskWallet.address
          : undefined,
      };
    },
    shouldDoWithMetamask(state, getters) {
      if (getters.supportAllWallet) {
        return state.walletType === "metamask";
      }
      return ifSupportMetamask(state.currentChain.network);
    },
    currentChainWalletAddress(state, getters) {
      const polkadotAddress = getters.polkadotWalletTransformAddress;
      const address = state.metamaskWallet.address;
      if (getters.supportAllWallet) {
        if (state.walletType === "polkadot") {
          return polkadotAddress;
        } else {
          if (state.currentChain.network === "litentry") {
            return encodeMetamaskAddressToLitentryAddress(
              address,
              state.currentChain.prefix
            );
          }
          return address;
        }
      }
      if (ifSupportPolkadot(state.currentChain.network)) {
        return polkadotAddress;
      }
      if (ifSupportMetamask(state.currentChain.network)) {
        return address;
      }
    },
    isLoginAndMatchNetwork(state, getters) {
      const supportPolkadot = ifSupportPolkadot(state.currentChain.network);
      const hasPolkadotAddress = !!getters.polkadotWalletTransformAddress;
      const supportMetamask = ifSupportMetamask(state.currentChain.network);
      const hasMetamaskAddress = !!state.metamaskWallet.address;
      const supportAllWallet = getters.supportAllWallet;

      return (
        !getters.ifLogin ||
        (!supportAllWallet && supportPolkadot && !hasPolkadotAddress) ||
        (!supportAllWallet && supportMetamask && !hasMetamaskAddress) ||
        (supportAllWallet && !hasMetamaskAddress && !hasPolkadotAddress)
      );
    },
  },
  mutations: {
    changeMetamaskWallet(state, value) {
      localStorage.setItem("metamaskWallet", JSON.stringify(value));
      state.metamaskWallet = value;
    },
    changePolkadotWallet(state, value) {
      localStorage.setItem("polkadotWallet", JSON.stringify(value));
      state.polkadotWallet = value;
    },
    changePolkadotWalletList(state, value) {
      localStorage.setItem("polkadotWalletList", JSON.stringify(value));
      state.polkadotWalletList = value;
    },
    changeSupportChainList(state, value) {
      state.supportChainList = value;
    },
    changeCurrentChain(state, value) {
      sessionStorage.setItem("currentChain", JSON.stringify(value));
      state.currentChain = value;
    },
    changeWalletType(state, value) {
      if (value) {
        state.walletType = value;
      }
      // console.log('handWalletT',state.walletType,state.availableWallet.polkadot)
      if (state.walletType === "metamask" && state.availableWallet.polkadot) {
        state.walletType = "polkadot";
        return;
      }
      if (state.walletType === "polkadot" && state.availableWallet.metamask) {
        state.walletType = "metamask";
      }
    },
    setAvailableWallet(state, value) {
      state.availableWallet = {
        ...state.availableWallet,
        ...value,
      };
    },
  },
  actions: {
    setAvailableWalletAndWalletType({ commit, state }, value) {
      if (value) {
        commit("setAvailableWallet", value);
      }

      let walletType = "";
      if (!state.availableWallet[state.walletType]) {
        // console.log("ava", state.walletType, state.availableWallet);
        const _avaWalletType = Object.keys(state.availableWallet).find(
          (wallet) => state.availableWallet[wallet]
        );
        // console.log("ava", _avaWalletType);
        if (_avaWalletType) {
          walletType = _avaWalletType;
        }
        commit("changeWalletType", walletType);
      }
    },
  },
};
